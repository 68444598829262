export const NOTIFICATION_TYPE_SALE_COMPLETED = 'sale-completed'
export const NOTIFICATION_TYPE_REGISTERED_DISPUTE = 'registered-dispute'
export const NOTIFICATION_TYPE_USER_DISPUTE_MESSAGE = 'user-dispute-message'

const NOTIFICATION_ICON = {
  [NOTIFICATION_TYPE_SALE_COMPLETED]: 'payment',
  [NOTIFICATION_TYPE_REGISTERED_DISPUTE]: 'feedback',
  [NOTIFICATION_TYPE_USER_DISPUTE_MESSAGE]: 'comment'
}

const NOTIFICATION_TITLE = {
  [NOTIFICATION_TYPE_SALE_COMPLETED]: 'Venta Realizada',
  [NOTIFICATION_TYPE_REGISTERED_DISPUTE]: 'Disputa registrada',
  [NOTIFICATION_TYPE_USER_DISPUTE_MESSAGE]: 'Mesaje en la reclamació'
}

export function getNotificationIcon (type) {
  return NOTIFICATION_ICON[type] ? NOTIFICATION_ICON[type] : 'notifications'
}

export function getNotificationTitle (type) {
  return NOTIFICATION_TITLE[type] ? NOTIFICATION_TITLE[type] : 'Notificación'
}
