<template>
  <v-navigation-drawer class="text-left app-navigation-drawer"
    :value="drawer"
    :temporary="$vuetify.breakpoint.smAndDown"
    :permanent="$vuetify.breakpoint.mdAndUp"
    app
    floating
    :mini-variant.sync="mini"
    @input="showDrawer"
    width="268">

    <div :style="mobileStyles" class="nav-wrapper">
      <v-app-bar class="app-toolbar" elevation="0" dense>
        <v-img class="logo" :src="logo"></v-img>
      </v-app-bar>

      <div class="nav-content">
        <v-list class="py-0 app-nav-list">
          <v-list-item  class="app-nav-general app-nav-bottom-divider" @click="goToRouteByName('home')">
            <v-list-item-content>
              <v-list-item-title class="app-general-information-text">Información general</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group class="app-nav-list-group app-nav-bottom-divider"
            sub-group
            :ripple="false"
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            prepend-icon=""
            no-action>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" class="app-nav-item-title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item :class="['app-nav-item', { 'app-nav-item-active': child.active }]" v-for="child in item.children" :key="child.title" link @click="goToRouteByName(child.route_name)">
              <v-list-item-content>
                <v-list-item-title :class="['app-nav-menu-item', { 'app-nav-menu-item-active': child.active }]">
                  {{child.title}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <template v-slot:appendIcon>
              <v-icon class="nav-expand-more-icon">expand_more</v-icon>
            </template>
          </v-list-group>

          <!-- <v-list-item v-if="$vuetify.breakpoint.mdAndUp && mini" class="text-right app-close-menu-item" @click.stop="changeMini" link>
            <v-list-item-content class="app-close-menu-content">
              <v-list-item-title>
                <v-icon class="app-nav-icon">chevron_right</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </div>
      <!-- <div v-if="$vuetify.breakpoint.mdAndUp && !mini" class="app-close-menu-item">
        <v-list-item class="text-right app-nav-top-divider" @click.stop="changeMini" link>
          <v-list-item-content class="app-close-menu-content">
            <v-list-item-title>
              <v-icon class="app-nav-icon">chevron_left</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div> -->
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import logo from '@/assets/logo.jpg'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      logo,
      mobileStyles: {}
    }
  },
  computed: {
    ...mapState('menu', ['drawer', 'mini', 'items'])
  },
  methods: {
    ...mapActions('menu', ['changeDrawer', 'setDrawer', 'changeMini', 'activateMenuItem', 'deactivateMenuItem']),
    calcViewport () {
      this.mobileStyles = {
        height: [window.innerHeight + 'px']
      }
    },
    showDrawer (value) {
      this.setDrawer(value)
    }
  },
  created () {
    window.addEventListener('resize', this.calcViewport)
  },
  destroyed () {
    window.removeEventListener('resize', this.calcViewport)
  },
  mounted () {
    this.calcViewport()
    this.activateMenuItem(this.$route.name)
  },
  watch: {
    $route (to, from) {
      this.deactivateMenuItem(from.name)
      this.activateMenuItem(to.name)
    }
  }
}
</script>
<style scoped>
.app-navigation-drawer {
  box-shadow: var(--app-navigation-drawer-border);
}
.app-toolbar {
  max-height: 3rem;
  box-shadow: var(--app-toolbar-border) !important;
  background-color: var(--app-menu-bg-color) !important;
}
.app-navigation-drawer >>> .v-navigation-drawer__content {
  overflow-y: hidden;
}
.nav-wrapper {
  display: flex;
  flex-direction: column;
}
.nav-content {
  flex-grow: 100;
  overflow-y: auto;
}
.logo {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-height: 2rem;
  max-width: 7.25rem;
}
.app-nav-general {
  cursor: pointer;
  padding-top: 0.375rem;
}
.app-general-information-text {
  font-size: 0.813rem;
  color: var(--app-menu-item-color);
  line-height: 1rem;
}
.app-nav-list-group >>> .v-list-group__items {
  padding-bottom: 1.25rem;
}
.app-nav-list-group >>> .v-list-item {
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}
.app-nav-list >>> .v-list-item {
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}
.app-nav-item-title {
  color: var(--app-menu-title-color);
  font-size: 0.813rem;
  font-weight: 700;
}
.nav-expand-more-icon {
  color: var(--app-menu-item-color);
}
.app-nav-item {
  padding: 0 1.5rem !important;
  min-height: 2rem;
}
.app-nav-item-active {
  background-color: var(--app-menu-bg-color-active);
}
.app-nav-item >>> .v-list-item__content{
  padding: 0;
}
.app-nav-menu-item {
  margin-left: 0.75rem;
  color: var(--app-menu-item-color);
  font-size: 0.813rem;
}
.app-nav-menu-item-active {
  color: var(--app-menu-color-active);
  font-weight: 500;
}
.app-nav-list-group >>> .v-list-item--active {
  color: var(--app-menu-item-color);
}
/* .app-close-menu-item >>> .v-list-item {
  min-height: 2.75rem;
}
.app-close-menu-content {
  padding: 0;
} */
</style>
