<template>
  <v-menu offset-y left v-if="customer">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar class="black--text" color="#FD9A1E" size="28">
          {{ getAbbreviatedName }}
        </v-avatar>
      </v-btn>
    </template>

    <v-card class="card-container">
      <div>
        <v-avatar class="black--text" color="#FD9A1E" size="80">
          {{ getAbbreviatedName }}
        </v-avatar>
        <v-list class="pt-0 text-center">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="company-name" v-text="customer.company_name"></v-list-item-title>
              <v-list-item-subtitle class="company-email" v-text="customer.email"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list class="py-0 text-left">
          <v-list-item-group class="app-list-item-group">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="menu-item-title">Configuración personal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="menu-item-title">Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="menu-item-title">Configuración de la cuenta</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>
        <v-btn class="log-out" outlined  @click="signOff">
          <span class="log-out-text">Salir</span>
        </v-btn>
        <v-divider></v-divider>

        <div class="company-container">
          <v-btn text class="company-button">
            <span class="company-button-text">Politicas de privacidad</span>
          </v-btn>

          <v-btn text class="company-button">
            <span class="company-button-text">Terminos y condiciones</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'

export default {
  mixins: [navigationMixin],
  computed: {
    ...mapState('account', ['customer']),
    ...mapGetters('account', ['getAbbreviatedName'])
  },
  methods: {
    ...mapActions('account', ['getCustomer']),
    ...mapActions('auth', ['logout']),
    ...mapActions('websocket', ['closeConnection']),
    signOff () {
      this.closeConnection()
      this.logout()
      this.goToRouteByName('login')
    }
  },
  mounted () {
    this.getCustomer()
  }
}
</script>
<style scoped>
.v-menu__content {
  margin-top: 0.688rem;
  margin-right: 0.375rem;
}
.card-container {
  max-width: 22.125rem;
  max-height: 31.938rem;
  min-width: 20.25rem;
  padding-top: 1.25rem;
}
.company-name {
  color: var(--app-menu-item-color);
  font-size: 1rem;
  font-weight: 500;
}
.company-email {
  color: var(--app-notification-message-color);
  font-size: 0.875rem;
}
.app-list-item-group >>> .v-list-item {
  min-height: 2.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.menu-item-title {
  color: var(--app-notification-title-color);
  font-size: 0.875rem;
  font-weight: 500;
}
.log-out {
  border-color: #DADCE0FF;
  width: 12.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.log-out-text {
  color: var(--app-notification-title-color);
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: initial;
}
.company-container {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.company-button {
  height: 1.5rem !important;
}
.company-button-text {
  color: var(--app-notification-message-color);
  font-size: 0.688rem;
  text-transform: initial;
  letter-spacing: normal;
}
</style>
