<template>
  <v-card class="overflow-hidden rounded-0" flat>
    <navigation-content>
      <template v-slot:content>
        <navigation-menu></navigation-menu>
        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
    </navigation-content>
  </v-card>
</template>
<script>
import navigationMixin from '@/mixins/navigation'
import NavigationMenu from '@/layouts/Dashboard/NavigationMenu'
import NavigationContent from '@/layouts/Dashboard/NavigationContent'

export default {
  mixins: [navigationMixin],
  components: {
    NavigationMenu,
    NavigationContent
  }
}
</script>
