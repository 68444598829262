<template>
  <v-menu offset-y left :close-on-content-click="false" ref="menu">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-badge color="#ED561BFF" v-on="on" dot left overlap>
          <v-icon class="notification-icon">mdi-bell-ring-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card class="notifications-container">
      <v-list two-line class="py-0 text-left" v-if="showNofFound">
        <template>
          <v-list-item>
            <template>
              <v-list-item-avatar>
                <v-icon class="notification-off-icon">notifications_off</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="notification-off-title">Notificaciones</v-list-item-title>
                <v-list-item-subtitle class="notification-off-message">No se encontraron notificaciones</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list>
      <v-list two-line class="py-0 text-left" v-else>
        <div v-for="notification in notifications.notifications" :key="notification.notification_id">
          <notification-item :notification="notification" :key="notification.notification_id" @closeMenu="closeMenu"></notification-item>
          <v-divider></v-divider>
        </div>
        <template v-if="notifications.next">
          <v-list-item class="more">
            <template>
              <div class="view-more">
                <v-progress-circular class="progress" indeterminate size="24" width="3" v-if="loadingMoreNotifications"></v-progress-circular>
                <v-btn text class="text-none load-more" small @click="loadMoreNotifications" v-else>Cargar Mas</v-btn>
              </div>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import NotificationItem from '@/components/Notification/NotificationItem'

export default {
  mixins: [navigationMixin],
  components: {
    NotificationItem
  },
  computed: {
    ...mapState('notification', ['notifications']),
    showNofFound () {
      return !this.notifications
    }
  },
  data () {
    return {
      loadingMoreNotifications: false
    }
  },
  methods: {
    ...mapActions('notification', ['getNotifications', 'getMoreNotifications']),
    async loadMoreNotifications () {
      this.loadingMoreNotifications = true
      await this.getMoreNotifications(this.notifications.next)
      this.loadingMoreNotifications = false
    },
    closeMenu () {
      this.$refs.menu.isActive = false
    }
  },
  mounted () {
    this.getNotifications()
  }
}
</script>
<style scoped>
.v-menu__content {
  margin-top: 0.688rem;
  margin-right: 0.375rem;
}
.notification-icon {
  color: var(--app-icon-color) !important;
}
.notifications-container {
  max-width: 22.125rem;
  max-height: 31.938rem;
}
.more {
  justify-content: center;
  min-height: 3rem;
  cursor: pointer;
}
.view-more {
  color: var(--app-notification-title-color);
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}
.notification-off-icon {
  background-color: var(--app-icon-active-color);
  color: #FFFFFFFF;
}
.notification-off-title {
  color: var(--app-notification-title-color);
  font-size: 0.875rem;
  font-weight: 500;
}
.notification-off-message {
  color: var(--app-notification-message-color);
  font-size: 0.813rem;
  white-space: break-spaces;
}
</style>
