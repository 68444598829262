<template>
  <div>
    <v-app-bar class="app-toolbar" app elevation="0" dense>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="changeDrawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <notification-menu></notification-menu>
      <user-menu></user-menu>
    </v-app-bar>
    <v-sheet class="overflow-y-auto sheet-container">
      <div class="main-wrapper" :style="contentStyles">
        <slot name="content"></slot>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import NotificationMenu from '@/components/Notification/NotificationMenu'
import UserMenu from '@/components/User/UserMenu'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      contentStyles: {}
    }
  },
  components: {
    NotificationMenu,
    UserMenu
  },
  methods: {
    ...mapActions('menu', ['changeDrawer']),
    calcViewport () {
      this.contentStyles = {
        minHeight: [window.innerHeight + 'px']
      }
    }
  },
  created () {
    window.addEventListener('resize', this.calcViewport)
  },
  destroyed () {
    window.removeEventListener('resize', this.calcViewport)
  },
  mounted () {
    this.calcViewport()
  }
}
</script>
<style scoped>
.app-toolbar {
  max-height: 3rem;
  box-shadow: var(--app-toolbar-border) !important;
  background-color: var(--app-menu-bg-color) !important;
}
.sheet-container {
  max-height: 100vh;
  background-color: var(--app-main-bg-color);
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
