<template>
  <v-list-item class="notification-lis-item" @click="goToNotification(notification)">
    <template>
      <v-list-item-avatar>
        <v-icon class="notification-icon" v-text="getNotificationIcon(notification.type)"></v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="notification-title" v-text="getNotificationTitle(notification.type)"></v-list-item-title>
        <v-list-item-subtitle class="notification-message" v-text="notification.message"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text v-text="dateFormat(notification.creation_date, 'D MMM')"></v-list-item-action-text>
        <v-icon :class="notification.status === 'read' ? 'icon-seen' : 'icon-unseen'">
          visibility
        </v-icon>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import { dateFormat } from '@/shared/format'
import { getNotificationIcon, getNotificationTitle } from '@/shared/notification'

export default {
  name: 'NotificationItem',
  mixins: [navigationMixin],
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  methods: {
    dateFormat,
    getNotificationIcon,
    getNotificationTitle,
    ...mapActions('notification', ['updateNotification']),
    goToNotification (notification) {
      if (notification.status === 'unread') {
        notification.status = 'read'
        this.updateNotification(notification)
      }

      this.$emit('closeMenu')
      this.goTo(notification.url)
    }
  }
}
</script>

<style scoped>
.notification-lis-item {
  cursor: pointer;
}
.notification-icon {
  background-color: var(--app-icon-active-color);
  color: #FFFFFFFF;
}
.notification-title {
  color: var(--app-notification-title-color);
  font-size: 0.875rem;
  font-weight: 500;
}
.notification-message {
  color: var(--app-notification-message-color);
  font-size: 0.813rem;
  white-space: break-spaces;
}
.icon-seen {
  color: var(--app-icon-inactive-color);
}
.icon-unseen {
  color: var(--app-icon-active-color);
}
</style>
